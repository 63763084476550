<template>
    <div class="pd20x flex1">
        <div class="contentBox h100pct ofA">
            <div class="filter" style="padding:10px 20px">
                <div>问题数量（{{data.length}}条）</div>
                <div>
                    <label>搜索：</label>
                    <a-input-search v-model="filter.keywords" placeholder="搜索问题" style="width: 200px"
                                    @search="()=>{pagination.page = 1;getData()}" class="mr10px"/>
                </div>
            </div>
            <a-table
                    :columns="columns"
                    :data-source="data"
                    :pagination="pagination"
                    :rowKey="'id'"
                    @change="changePage"
            >
                <template slot="questionUser" slot-scope="text, record">
                    <div>
                        <img :src="record.headimgurl" style="border-radius: 50%;width:35px">
                        {{record.nickname}}
                    </div>
                </template>
                <template slot="add_time" slot-scope="text">
                    {{formatDate(text*1000)}}
                </template>
                <template slot="operation" slot-scope="text, record">
                    <a-icon type="message" @click="lookItem(record.id)" style="font-size:20px;margin-right: 10px"/>
                    <a-icon type="minus-circle"  @click="deleteItem(record.id)" style="font-size:20px"/>
                </template>
            </a-table>
        </div>
    </div>
</template>
<script>
    const columns = [{
        title: '序号',
        dataIndex: 'id',
        scopedSlots: {customRender: 'id'},
        align: 'center'
    },
        {
            title: '提问人',
            dataIndex: 'name',
            scopedSlots: { customRender: 'questionUser' },
            align:'center'
        },
        {
            title: '问题标题',
            dataIndex: 'title',
            align: 'center'
        },
        {
            title: '提出时间',
            dataIndex: 'add_time',
            align: 'center',
            scopedSlots: {customRender: 'add_time'},
        },
        {
            title: '操作',
            dataIndex: 'operation',
            scopedSlots: {customRender: 'operation'},
        },
    ];
    import {formatDate} from '@/libs/moment'
    import * as http from '@/libs/practice'
    import {routerlink} from '@/mixins/routerlink'

    export default {
        name: 'question',
        components: {},
        mixins: [routerlink],
        data() {
            return {
                options: [],
                columns,
                filter: {
                    cate_id: 0,
                    keywords: ''
                },
                pagination: {
                    page: 1,
                    size: '15',
                    pageSize: 15
                },
                data: [],
                areaval: '',
                openClasses: false,
                currentItem: '',
                editClass: false
            }
        },
        mounted(){
            this.$nextTick(()=>{
                Promise.all([]).then(res=>{
                    this.getData()
                })
            })
        },
        methods: {
            formatDate,
            getData() {
                let request = {...this.filter, ...this.pagination}
                if (this.filter.cate_id == 0) {
                    request.cate_id = null
                }
                http.get_question_list(request).then(res => {
                    const pagination = {...this.pagination}
                    pagination.total = res.total;
                    this.data = res.data
                    this.pagination = pagination;

                }).catch(error => {
                    console.log(error)
                })
            },
            lookItem(id){
                this.$router.push({
                    path: '/practice/answer',
                    query:{
                        id:id
                    }
                })
            },
            deleteItem(id) {
                let _this = this
                this.$confirm({
                    title: '是否确定删除该条留言信息?',
                    centered: true,
                    onOk() {
                        http.del_question({id}).then(res => {
                            let {page, pagesize, total} = _this.pagination
                            if (page > 1 && Number(page - 1) * 15 == total - 1) {
                                _this.pagination.page = Number(page - 1)
                            }
                            _this.getData()
                        }).catch(error => {
                            console.log(error)
                        })
                    },
                });
            },
            changePage({current}) {
                this.pagination.page = current
                this.getData()
            },
            hideModal() {

            },
            onChange() {

            },
        }
    }
</script>
<style lang="less" scoped>
    .imgWrap {
        width: 120px;
        height: 70px;
        overflow: hidden;
        border-radius: 4px;
        margin: auto;

        img {
            width: 100%;
        }
    }
</style>
